import Nav from './Nav.jsx';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import SimpleFooter from './SimpleFooter';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

// FAQ Data
const faqs = [
  {
    question: "Who do you treat?",
    answer: "I treat both adults and adolescents."
  },
  {
    question: "How much do you charge for a session?",
    answer: "Each session costs $100, payable at the end. I accept payments via credit card through my secure Venmo business account and my secure portal on Therapy Notes."
  },
  {
    question: "Is insurance accepted?",
    answer: "Yes, I accept United Healthcare."
  },
  {
    question: "Do you conduct in-person sessions?",
    answer: "No, I only offer telehealth sessions through Therapy Notes, which uses HIPAA-compliant software."
  },
  {
    question: "What therapeutic approach do you use?",
    answer: "My primary treatment approach is Cognitive Behavioral Therapy built on a Person-Centered foundation. I incorporate additional modalities based on the client's needs and treatment goals. I've worked as an inpatient therapist in a hospital setting, where I facilitated group therapy, managed crisis interventions, and provided psychoeducation to patients and their families."
  },
  {
    question: "When are your available slots?",
    answer: "I provide 50-minute sessions from Monday to Thursday during late afternoons and evenings. The frequency can be weekly, bi-weekly, or monthly. Please contact for specific availability."
  },

];

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel0');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div className='bg-gradient-to-b from-blue-gray-900 to-blue-gray-800 min-h-screen p-4 sm:p-0 min-w-full overflow-x-hidden faq'> 
      <Nav />
      <div className='mt-8 mx-auto max-w-screen-xl p-1'> {/* Adjust the size as needed */}
        {faqs.map((faq, i) => (
          <Accordion expanded={expanded === `panel${i}`} onChange={handleChange(`panel${i}`)} key={i}>
            <AccordionSummary aria-controls={`panel${i}d-content`} id={`panel${i}d-header`}>
              <Typography>{faq.question}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{faq.answer}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <div> <SimpleFooter /> </div>
    </div>
  );
}
