import React from 'react';
import logo from '../images/peyprofile.jpg';
import Typography from '@mui/material/Typography';
import Nav from './Nav.jsx';
import SimpleFooter from './SimpleFooter';
import '../components/Hero.css';

const specialties = [
    "Depression",
    "Anxiety",
    "Substance Use Disorder",
    "Co-occurring Disorders",
    "Mood Disorders",
    "Personality Disorders",
    "Psychotic Disorders",
    "Self-harming",
    "Coping Skills",
    "Teen Issues",
    "Relationship Issues",
    "Rehabilitation & individuals with disabilities"
];

const ProfileSection = ({ logo }) => (
    <div className="text-white text-center mb-8 mt-8 py-5 px-5">
        <img src={logo} alt="Peyton" className="rounded-md w-50 mx-auto shadow-xl" />
        <Typography 
            variant="h5" 
            color="blue-gray" 
            style={{ fontFamily: "Playfair Display, serif" }}>
            Peyton Shaw, M.S., LPC
        </Typography>
    </div>
);

const CredentialsSection = () => (
    <div className="text-white text-center mt-5 px-2" > 
        <Typography variant="h5" color="blue-gray" className="mb-4 py-1" style={{ fontFamily: "Playfair Display, serif" }} >Credentials</Typography>
        <p className="text-sm text-blue-gray-200 mb-5">
            Licensed Professional Counselor (LPC)<br /><br />
            <u>University of North Texas</u><br />
            MS in Rehabilitation Counseling<br />
            BS in Rehabilitation Studies with a minor in Addiction Studies
        </p>
        <Typography variant="h5" color="blue-gray" className="mb-4 py-1" style={{ fontFamily: "Playfair Display, serif" }} >Contact</Typography>
        <p className="text-sm text-blue-gray-200">
            Scheduling, cost, & initial intake questions?
            <br />
            Email: <a href="mailto:peyton@peytonshawcounseling.com" className="text-blue-400 hover:underline">peyton@peytonshawcounseling.com</a> 
            <br />
            Call or Text: <a href="tel:9726893738" className="text-blue-400 hover:underline">972.689.3738</a>
        </p>
    </div>
);

const SpecialtiesList = () => (
    <div className="text-sm text-blue-gray-200 mb-5 text-center">
        {specialties.map(specialty => (
            <p key={specialty}>{specialty}</p>
        ))}
    </div>
);

const About = () => (
    <div className=' min-h-screen min-w-full overflow-x-hidden bg-gradient-to-b from-blue-gray-900 to-blue-gray-800 hero'>
        <Nav />
        <div className="lg:mt-16 flex flex-col sm:flex-row items-center justify-center w-full max-w-6xl mx-auto">
            <div className="sm:w-1/3 flex flex-col items-center justify-center">
                <ProfileSection logo={logo} />
            </div>
            <div className="sm:w-2/3 flex flex-col items-center justify-start pl-0 sm:pl-8">
                <Typography variant="h5" color="blue-gray" className=" pb-2 text-white" style={{ fontFamily: "Playfair Display, serif" }}>
                    Specialties and Expertise
                </Typography>
                <SpecialtiesList />
                <CredentialsSection />
            </div>
        </div>
        <div className="text-white text-center mt-10" >
        <SimpleFooter />
        </div>
    </div>
);

export default About;