import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  Menu,
  MenuHandler,
  MenuList,
  MenuItem,
  Card,
  IconButton,
} from "@material-tailwind/react";
import {
  QuestionMarkCircleIcon,
  UserCircleIcon,
  PencilSquareIcon,
  ChevronDownIcon,
  ClipboardIcon,
  Bars2Icon,
  VideoCameraIcon,
} from "@heroicons/react/24/outline";

// nav list menu
const navListMenuItems = [
  {
    title: "Virtual",
    description:
      "Currently, we are only offering virtual / telehealth sessions.",
  }
];
 
function NavListMenu() {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
 
  const triggers = {
    onMouseEnter: () => setIsMenuOpen(true),
    onMouseLeave: () => setIsMenuOpen(false),
  };
 
  const renderItems = navListMenuItems.map(({ title, description }) => (
    <a href="#" key={title}>
      <MenuItem>
        <Typography variant="h6" color="blue-gray" className="mb-1 px-1">
          {title}
        </Typography>
        <Typography variant="small" color="gray" className="font-normal px-1 max-w-[90%]">
          {description}
        </Typography>
      </MenuItem>
    </a>
  ));
 
  return (
    <React.Fragment>
      <Menu open={isMenuOpen} handler={setIsMenuOpen}>
        <MenuHandler>
          <Typography as="a" href="#" variant="small" className="font-normal">
            <MenuItem
              {...triggers}
              className="hidden items-center gap-2 text-blue-gray-900 lg:flex lg:rounded-full"
            >
              <ClipboardIcon className="h-[18px] w-[18px]" /> Services{" "}
              <ChevronDownIcon
                strokeWidth={2}
                className={`h-3 w-3 transition-transform ${
                  isMenuOpen ? "rotate-180" : ""
                }`}
              />
            </MenuItem>
          </Typography>
        </MenuHandler>
        <MenuList
          {...triggers}
          className="hidden md:w-[25rem] sm:w-[28rem] grid-cols-7 gap-3 overflow-visible lg:grid"
        >
          <Card
            color="blue-gray"
            shadow={false}
            variant="gradient"
            className="col-span-3 grid h-full w-full place-items-center rounded-md "
          >
            <VideoCameraIcon strokeWidth={1} className="h-28 w-28" />
          </Card>
          <ul className="col-span-4 flex w-full flex-col gap-1">
            {renderItems}
          </ul>
        </MenuList>
      </Menu>
      <MenuItem className="flex items-center gap-2 text-blue-gray-900 lg:hidden">
        <ClipboardIcon className="h-[18px] w-[18px]" /> Services{" "}
      </MenuItem>
      <ul className="ml-6 flex w-full flex-col gap-1 lg:hidden">
        {renderItems}
      </ul>
      
    </React.Fragment>
  );
}
 
// nav list component
const navListItems = [
  {
    label: "About",
    icon: UserCircleIcon,
  },
  {
    label: "Book a Session",
    icon: PencilSquareIcon,
  },
  {
    label: "FAQ",
    icon: QuestionMarkCircleIcon,
  },
];
 
function NavList() {
  const navigate = useNavigate();

  const handleFaqClick = () => {
    navigate('/faq');
  };

  const handleAboutClick = () => {
    navigate('/about');
  };
  
  return (
    <ul className="mb-4 mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center ">
      <NavListMenu />
      {navListItems.map(({ label, icon }, key) => (
        <Typography
          key={label}
          as="a"
          href="#"
          variant="small"
          color="blue-gray"
          className="font-normal"
          onClick={label === 'FAQ' ? handleFaqClick : label === 'About' ? handleAboutClick : null}
          >
          <MenuItem className="flex items-center gap-2 lg:rounded-full">
            {React.createElement(icon, { className: "h-[18px] w-[18px]" })}{" "}
            {label}
          </MenuItem>
        </Typography>
      ))}
    </ul>
  );
}

export default function ComplexNavbar() {
  const [isNavOpen, setIsNavOpen] = React.useState(false);
  const toggleIsNavOpen = () => setIsNavOpen((cur) => !cur);
  const navigate = useNavigate();

  function handleLogoClick(event) {
    event.preventDefault();
    navigate("/#");
  }
  
  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setIsNavOpen(false)
    );
  }, []);

  return (
    <Navbar className="mx-auto p-2 lg:pr-1 border-none shadow-none bg-white">
      <div className="grid grid-cols-2 justify-items-start items-center text-blue-gray-900">
      <Typography
  as="a"
  href="#"
  className="mr-4 ml-2 cursor-pointer py-1.5 font-bold text-sm whitespace-nowrap"
  onClick={handleLogoClick}
>
  Peyton Shaw Counseling
</Typography>

        <div className="absolute top-2/4 left-2/4 hidden -translate-x-2/4 -translate-y-2/4 lg:block ">
          <NavList />
        </div>
        <IconButton
          size="sm"
          color="blue-gray"
          variant="text"
          onClick={toggleIsNavOpen}
          className="ml-auto mr-2 lg:hidden"
        >
          <Bars2Icon className="h-6 w-6" />
        </IconButton>
        {/* Add the "Book Now" button */}
        <Button
          color="bg-deepOrange-A200"
          size="sm"
          className="hidden lg:block lg:ml-auto rounded-full lg:px-5 bg-[#E4C8B9] shadow-gray-300 hover:shadow-gray-400"
          onClick={() => console.log("Book Now clicked")}
        >
          Book Now
        </Button>
      </div>
      <MobileNav open={isNavOpen} className="overflow-scroll overflow-x-hidden">
  <NavList />
</MobileNav>
    </Navbar>
  );
}
