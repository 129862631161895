import { Typography } from "@material-tailwind/react";
import logo from '../images/peyprofile.jpg';
 
export default function Example() {
  return (
    <footer className="w-full bg-sky-blue-200 p-4 md:p-9">
      <div className="flex flex-col items-center gap-y-6 gap-x-12 bg-none text-center md:flex-row md:justify-between">
        <img src={logo} alt="logo-ct" className="w-8 rounded-full md:w-10" />
        <ul className="flex flex-wrap items-center gap-y-2 gap-x-6">
          <li>
            <Typography
              as="a"
              href="#"
              color="white"
              className="font-normal text-sm transition-colors text-gray-100 hover:text-deep-orange-50 focus:text-blue-500 md:text-base"
            >
              About Us
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="white"
              className="font-normal text-sm transition-colors text-gray-100 hover:text-deep-orange-50 focus:text-blue-500 md:text-base"
            >
              License
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="white"
              className="font-normal text-sm transition-colors text-gray-100 hover:text-deep-orange-50 focus:text-blue-500 md:text-base"
            >
              Contribute
            </Typography>
          </li>
          <li>
            <Typography
              as="a"
              href="#"
              color="#"
              className="text-gray-100 font-normal text-sm transition-colors hover:text-deep-orange-50 focus:text-blue-500 md:text-base"
            >
              Contact Us
            </Typography>
          </li>
        </ul>
      </div>
      <hr className="my-8 border-gray-1000" />
      <Typography color="text-deep-orange-100" className="text-center font-normal text-sm md:text-base text-gray-100">
        &copy; 2023 Peyton Shaw Counseling. All rights reserved.
      </Typography>
    </footer>
  );
}