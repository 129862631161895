import React, { useState, Fragment } from 'react';
import { useForm, Controller } from 'react-hook-form';
import '../components/Hero.css';
import {
  Card,
  Input,
  Button,
  Typography,
  Textarea,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
} from "@material-tailwind/react";
import { Spinner } from "@material-tailwind/react";
import { GoogleReCaptchaProvider, useGoogleReCaptcha } from "react-google-recaptcha-v3";

function ContactForm() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [successDialog, setSuccessDialog] = useState(false);
  const { register, handleSubmit, control, setValue, formState: { errors } } = useForm();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const handleOpen = () => setOpen(!open);

  const handlePhoneChange = (event) => {
    const onlyNums = event.target.value.replace(/[^\d]/g, '');
    if (onlyNums.length < 4) {
      return onlyNums;
    } else if (onlyNums.length < 7) {
      return `${onlyNums.slice(0, 3)}-${onlyNums.slice(3)}`;
    } else {
      return `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
    }
  };


  const onSubmit = async (data) => {
    data.phone = data.phone.replace(/[^\d]/g, '');
    data.email = data.email.toLowerCase();
    data.firstName = data.firstName.charAt(0).toUpperCase() + data.firstName.slice(1);
    data.lastName = data.lastName.charAt(0).toUpperCase() + data.lastName.slice(1);

    if (Object.keys(errors).length > 0) {
      handleOpen();
      return;
    }

    setLoading(true);
    if (!executeRecaptcha) {
      return;
    }
    const recaptchaValue = await executeRecaptcha("contact");
    data['g-recaptcha-response'] = recaptchaValue;
    try {
      const response = await fetch('https://shawsteadgroup.com/contacts.php', {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      const responseData = await response.json();

      if (responseData.status === 'success') {
        console.log('Message sent successfully');
        setIsSubmitted(true);
        setSuccessDialog(true); // Open the success dialog
      } else {
        console.log('Failed to send the message');
        handleOpen();
      }
    } catch (error) {
      console.error('Error:', error);
      handleOpen();
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    }
  };


  return (
    <Fragment>
      {isSubmitted ? null : (
        <div className='text-[#E4C8B9] bg-gradient-to-r from-gray-300 to-gray-200 pb-4 px-4 rounded-md shadow-lg'>
          <div className="mx-auto text-center">
            <Card color="transparent" shadow={false}>
                <form onSubmit={handleSubmit(onSubmit)} className="space-y-6 mt-8 mb-2">
                  <div className="mb-4 flex flex-col gap-6">
                  <Input
  size="lg"
  type="text"
  name="firstName"
  label="First Name"
  color='blue-gray'
  {...register('firstName', { required: true, pattern: /^[A-Za-z\s]+$/, maxLength: 80 })}
  onChange={(e) => {
    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
  }}
/>
{errors.firstName && <p className="text-red-500">Invalid First Name</p>}
<Input
  label='Last Name'
  size="lg"
  type="text"
  name="lastName"
  color='blue-gray'
  {...register('lastName', { required: true, pattern: /^[A-Za-z\s]+$/, maxLength: 80 })}
  onChange={(e) => {
    e.target.value = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
  }}
/>
{errors.lastName && <p className="text-red-500">Invalid Last Name</p>}
<Input
  size="lg"
  type="email"
  name="email"
  label="Email Address"
  color='blue-gray'
  {...register('email', { required: true, pattern: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/i })}
  onChange={(e) => {
    e.target.value = e.target.value.toLowerCase();
  }}
/>
{errors.email && <p className="text-red-500">Invalid Email Address</p>}
                    <Controller
  control={control}
  name="phone"
  rules={{ required: true, pattern: /\(\d{3}\)\s\d{3}-\d{4}/ }}
  render={({ field }) => (
    <Input
      size="lg"
      type="tel"
      label="Phone Number"
      color='blue-gray'
      value={field.value}
      {...field}
      onChange={e => field.onChange(handlePhoneChange(e))}
    />
  )}
/>

{errors.phone && <p className="text-red-500">Invalid Phone Number</p>}
                    <Textarea
                      size="lg"
                      type="textarea"
                      name="message"
                      label="Message"
                      color='blue-gray'
                      {...register('message', { required: true, maxLength: 500 })} />
                    {errors.message && <p className="text-red-500">Invalid Message</p>}
                  </div>
                  {loading ? (
                    <div className='flex justify-center items-center h-12'>
                      <Spinner color="blue" />
                    </div>
                  ) : (
                    <Button className="mt-6 bg-gradient-to-b from-blue-gray-900 to-blue-gray-800 text-deep-orange-100 shadow-none hover:shadow-deep-orange-100" fullWidth type="submit">
                      Submit
                    </Button>
                  )}
                </form>
            </Card>
          </div>
        </div>
      )}
      <Dialog open={open} handler={handleOpen}>
        <DialogHeader>
          <Typography variant="h5" color="blue-gray">
            Errors in Form Submission!
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid place-items-center gap-4">
          {Object.entries(errors).map(([field, error]) => (
            <Typography key={field} className="text-center font-normal">
              {field}: {error.message}
            </Typography>
          ))}
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="gradient" onClick={handleOpen}>
            Ok, Got it
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog open={successDialog} handler={() => setSuccessDialog(false)}>
        <DialogHeader>
          <Typography 
            variant="h5" 
            color="blue-gray">
            
          </Typography>
        </DialogHeader>
        <DialogBody divider className="grid place-items-center gap-4">
          <Typography className="text-center font-normal">
            Thanks for submitting our form inquiring about therapy services! We got your message and will be in touch soon!
          </Typography>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="gradient" color='blue-gray' onClick={() => setSuccessDialog(false)}>
            Ok, Got it
          </Button>
        </DialogFooter>
      </Dialog>
    </Fragment>
  );
}

export default ContactForm;
