import React from 'react';
import { useNavigate } from 'react-router-dom';
import '../components/Hero.css';
import logo from '../images/peyprofile.jpg';
import Nav from './Nav.jsx';
import SimpleFooter from './SimpleFooter';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Typography,
  Tooltip,
} from "@material-tailwind/react"
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';  // import GoogleReCaptchaProvider
import ContactForm from './ContactForm';

const Hero = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate('/faq');
  };

  return (
    <div className='min-h-screen p-4 sm:p-0 min-w-full overflow-x-hidden bg-gradient-to-b from-blue-gray-900 to-blue-gray-800 hero'>
      <Nav />
      <div className="pt-8 sm:pt-20 min-h-screen flex flex-col items-center justify-center">
        <div className="text-white">
          <div className="mx-auto text-center flex flex-col justify-center">
            <div className="process-step-titles responsive-titles">
              <div className="process-step-container process-step-1">
                <div className="process-step-title-container">
                  <h2 className="process-step-title text-sm sm:text-base lg:text-xl">EMPOWERING</h2>
                  <div className="process-step-title-overlay">EMPOWERING</div>
                </div>
              </div>
              <div className="process-step-container process-step-2">
                <div className="process-step-title-container">
                  <h2 className="process-step-title text-sm sm:text-base lg:text-xl">THROUGH</h2>
                  <div className="process-step-title-overlay">THROUGH</div>
                </div>
              </div>
              <div className="process-step-container process-step-3">
                <div className="process-step-title-container">
                  <h1 className="process-step-title text-sm sm:text-xl lg:text-2xl">THERAPY</h1>
                  <div className="process-step-title-overlay">THERAPY</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="selection:bg-sky-300 selection:text-sky-900 mt-10">
          <img src={logo} alt="Peyton" className="rounded-full w-24 sm:w-32 h-24 sm:h-32 mx-auto" />
          <div className="items-center mt-4 sm:mt-0">
            <blockquote className="text-lg sm:text-xl italic font-semibold dark:text-gray-200 px-4 sm:px-6">
              <svg
                aria-hidden="true"
                className="w-8 h-8 sm:w-10 sm:h-10 text-[#b1b1b1] dark:text-[#E4C8B9]"
                viewBox="0 0 24 27"
                fill="gray-300"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14.017 18L14.017 10.609C14.017 4.905 17.748 1.039 23 0L23.995 2.151C21.563 3.068 20 5.789 20 8H24V18H14.017ZM0 18V10.609C0 4.905 3.748 1.038 9 0L9.996 2.151C7.563 3.068 6 5.789 6 8H9.983L9.983 18L0 18Z"
                  fill="#ffccbc"
                />
              </svg>
            </blockquote>
            <p className="mx-auto text-white px-4 text-base md:text-xl flex-row italic lg:w-[1000px]" style={{ fontFamily: "Playfair Display, serif" }}>
            Life can get pretty chaotic, and I get it. Whether you're stuck in the tangled mess of adulthood or navigating the rollercoaster ride of adolescence, or just needing someone to talk to, I'm here to lend a listening ear. As a licensed professional counselor, my primary focus is to meet each individual where they are at, providing a safe and supportive space to explore thoughts, feelings, and experiences. It is an honor to be part of your therapeutic journey, and I look forward to supporting you every step of the way!

            </p>
          </div>
          <div className="relative flex mt-10 items-center">
            <div className="border-t bg-gradient-to-r from-gray-400 to-gray-200 flex-grow rounded-md mx-2 sm:mx-4 md:mx-20"></div>
            <span className="flex-shrink text-gray-400"></span>
          </div>
        </div>
        <div>
          <p className="text-md font-bold text-deep-orange-100 py-5 text-center" style={{ fontFamily: "Playfair Display, serif" }}>
            Are you ready to start your journey to wellness?
          </p>
        </div>
        <div className="text-center w-full lg:w-[600px]">
          <GoogleReCaptchaProvider reCaptchaKey="6Lda9MsmAAAAACaUjAb0naPDfY45NlniMqP6xygg">
            <ContactForm />
          </GoogleReCaptchaProvider>
        </div>
      </div>
      <SimpleFooter />
    </div>
  );
};

export default Hero;
